/*  Main Page */
.accountBox{
    margin-top: 60px;
    width: 100%;
    min-height: 650px;
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0 0 2px rgba(15, 15, 15, 0.28);
    position: relative;
    overflow: hidden;

}

.topContainer{
    width: 100%;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 1.8em;
    padding-bottom: 5em;
}

.backDrop{
    width: 200%;
    height: 650px;
    position: absolute;
    display: flex;
    flex-direction: column;
    border-radius: 100%;
    background: red;
    top: -290px;
    left: -70px;
}

.headerContainer{
    width: 100%;
    display: flex;
    flex-direction: column;

}
.submitButton:disabled{
    display: none;
}


.headerContainer h2{
    font-size: 30px;
    font-weight: 600;
    line-height: 1.24;
    color: white;
    z-index: 10;
    margin: 0;
    margin-left: auto;
    margin-right: auto;
}

.headerContainer h5{
    color: white;
    font-weight: 500;
    font-size: 11px;
    margin-top: 6px;
    z-index: 10;
    margin-left: auto;
    margin-right: auto;
}

/*  Function Page */
.boxContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;


}

.formContainer{
    width: 75%;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 2.5px rgba(15, 15, 15, 0.19);
    max-width: 500px;
}

.muteLink{
    cursor: pointer;
    margin-top: 1em;
    font-size: 11px;
    color: rgba(200, 200, 200, 0.8);
    font-weight: 500;
    text-decoration: none;
}

.boldLink{
    font-size: 12px;
    color: red;
    font-weight: 500;
    text-decoration: none;
}

.formContainer input{
    width: 100%;
    
    height: 42px;
    outline: none;
    border: 1px solid rgba(200, 200, 200, 0.3);
    padding: 0px 10px;
    border-bottom: 1.4px solid transparent;
    transition: all 200ms ease-in-out;
    font-size: 12px;

}

.formContainer input:focus{
    outline: none;
    border: 2px solid red;
    border-bottom: none;
}

.formContainer input::placeholder{
    color: rgba(200, 200, 200, 1);
}

.formContainer input:not(:last-of-type){
    border-bottom: 1.5px solid rgba(247, 100, 100, 0.4) ;
}

.submitButton {
    margin-top: 15px;
    background-color: red !important;
    outline:none !important; 
    border-style: none !important;
    cursor: pointer;
    transition: all, 240ms ease-in-out;
}



.submitButton:hover{
    filter: brightness(1.9);
}

.innerContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 1.8em;
}

.alert{
    line-height: 1px;
    font-size: 10px;
    margin: 0 !important;
}

/*  Forgot Password */
.emailModal{
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}


/* CSS */
.button-71 {
  background-color: #0078d0;
  border: 0;
  width: 100%;
  border-radius: 56px;
  color: #fff !important;
  cursor: pointer;
  display: inline-block; 
  font-family: system-ui,-apple-system,system-ui,"Segoe UI",Roboto,Ubuntu,"Helvetica Neue",sans-serif;
  font-size: 18px;
  font-weight: 600;
  outline: 0;
  padding: 16px 21px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all .3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-71:before {
  background-color: initial;
  background-image: linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%);
  border-radius: 125px;
  content: "";
  height: 50%;
  left: 4%;
  opacity: .5;
  position: absolute;
  top: 0;
  transition: all .3s;
  width: 92%;
}

.button-71:hover {
  box-shadow: rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .1) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px;
  transform: scale(1.05);
}

@media (min-width: 768px) {
  .button-71 {
    padding: 16px 48px;
  }
}

.addForm p{
    margin-top: 0px;
    margin-bottom: 5px;
}

.addForm textarea{
    margin-top: 0px;
    margin-bottom: 5px;
    width: 100%;
    border-color: black;
}

.addForm textarea:focus{
    outline: none !important;
}

.addForm input{
    margin-top: 0px;
    margin-bottom: 5px;
    width: 100%;
}

.addForm input:focus{
    outline: none !important;
}
