.contactContain{
    margin-top: 60px;  
    margin-bottom: 100px;
}



.contactLeft{
    background: red;
    padding-top: 70px;
    justify-content: center;
    text-align: center;
    border-radius: 20px;
}

.smallBox .contactLeft{
    border-top-right-radius: 0px;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
}

.contactRight{
    padding-top: 70px;
    justify-content: center;
    text-align: center;
}

.smallBox .contactRight{
    padding-bottom: 20px;
}


/*   Left Side Style   */
.contactTitle{
    margin-top: 100px;
    color: white;
    text-shadow: 2px 8px 6px rgba(0,0,0,0.2),
    0px -5px 35px rgba(255,255,255,0.3);
}

.smallBox .contactTitle{
    margin-top: 50px;
}

.formControl input{
    background: transparent !important;
}

.contactTitle h1{
    font-size: 32px;
    line-height: 10px;
}

.contactForm{
    padding: 0 30px;
}

.contactLeft form{
    margin-top: 50px;
    transition: all 4s ease-in-out;
}

.formControl{
    width: 100%;
    background: transparent;
    border: none;
    outline: none;
    border-bottom: 1px solid black;
    color: white;
    font-size: 18px;
    margin-bottom: 16px;
}

.contactLeft input{
    height: 45px;
}

.contactLeft input::placeholder{
    color: white;
}

.contactLeft textarea::placeholder{
    color: white;
}

.contactLeft form .submit{
    background: black;
    border-color: transparent;
    color: white;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 2px;
    height: 50px;
    margin-top: 20px;
    margin-bottom: 200px;
}

.smallBox .contactLeft form .submit{
    margin-bottom: 100px;
}

/*   Right Side Style   */
.contactTitleRight{
    margin-top: 100px;
    transition: all 4s ease-in-out;
    text-shadow: 2px 8px 6px rgba(0,0,0,0.2),
    0px -5px 35px rgba(255,255,255,0.3);
}

.smallBox .contactTitleRight{
    margin-top: 0;
}

.contactTitleRight h1{
    font-size: 32px;
    line-height: 10px;
}

.contactRightBox{
    margin-top: 50px;
}

.smallBox .contactRightBox{
    margin-top: 20px;
}

.contactRightBox i {
	background-color: #33383b;
	color: #ffffff;
	font-size: 25px;
	width: 38px;
	height: 38px;
	border-radius: 50%;
	text-align: center;
	line-height: 42px;
	margin: 10px 15px;
	vertical-align: middle;
  }

  .smallBox .contactRightBox i{
    margin: 0;
  }

  .contactRightBox .col-sm-4{
      text-align: end;
      margin-top: 10px;
  }

  .contactRightBox .col-sm-8{
      text-align: start;
      margin-top: 20px;
  }

  .contactRightBox p{
      margin: 0;
  }

  .boldPart{
      font-weight: bold;
      text-decoration: underline;
  }

  .smallBox .contactRightBox .col-sm-4{
      text-align: center !important;
      justify-content: center !important;
  }

  .smallBox .contactRightBox .col-sm-8{
    text-align: center !important;
    justify-content: center !important;
}

.smallBox .btmRow{
    margin-top: 20px;
}
