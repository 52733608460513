.catMain{
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: 5px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    box-sizing: border-box;
    justify-content: space-between;
    padding-left: 10px;
}

.smallBox .catMain{
    margin-left: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
}

.catCard{
    width: 200px;
    margin-bottom: 0;
    border-style: ridge;
    border-radius: 15px;
    border-color: #ffe6e6 !important;
    transform: scale(0.8);
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
    transition: all .3s ease-in-out;
    justify-content: center;
    text-align: center;
}

.smallBox .catCard{
    margin-bottom: 0px;
    transform: scale(.9);
    padding-bottom: 0 !important;
}

.cardTitle{
    font-size: 20px;
    margin-bottom: 30px;
}

.smallBox .cardTitle{
    font-size: 15px;
    margin-bottom: 10px;
}

.cardStock{
    display: none;
}

.smallBox .cardStock{
  
    font-size: 10px;
}


.catCard:hover{
    border-style: none;
    box-shadow: none;
    background: #ffe6e6;
    transform: scale(0.9);
    transition: all .3s ease-in-out;
}

.smallBox .catCard:hover{
    transform: scale(1);
}

.catCard:hover .cardTitle{
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 15px;
}

.catCard:hover .cardStock{
    display: block;

}

.catCard img{
    margin-top: -6vw;
    width: 100%;
}

.smallBox .catCard img{
    margin-top: -7vw;
    width: 50%;
}

/* Button */
.button-71 {
    background-color: red !important;
    border: 0;
    width: 100%;
    border-radius: 56px;
    color: #fff !important;
    cursor: pointer;
    display: inline-block; 
    font-family: system-ui,-apple-system,system-ui,"Segoe UI",Roboto,Ubuntu,"Helvetica Neue",sans-serif;
    font-size: 18px;
    font-weight: 600;
    outline: 0;
    padding: 16px 21px;
    margin-bottom: 10px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: all .3s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }

.smallBox .button-71{
    font-size: 12px;
}
  
  .button-71:before {
    background-color: initial;
    background-image: linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%);
    border-radius: 125px;
    content: "";
    height: 50%;
    left: 4%;
    opacity: .5;
    position: absolute;
    top: 0;
    transition: all .3s;
    width: 92%;
  }
  
  .button-71:hover {
    box-shadow: rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .1) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px;
    transform: scale(1.05);
  }
  
  @media (min-width: 768px) {
    .button-71 {
      padding: 16px 48px;
    }
  }