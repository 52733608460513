@import url(https://fonts.googleapis.com/css2?family=Prompt:wght@700&display=swap);
body {
  margin: 0;
  font-family: 'Poppins' -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.App {
  text-align: center;
  font-family: "Poppins";
}

/* Justify  */
.topContain{
  margin-top: 75px !important;
  display: flex; 
  flex-direction: row; 
  justify-content: space-between; 
}

.rightPanel{
  display: flex;
  flex-direction: column;
}

.widen{
  width: 100%;
}

/* Top Nav Display  */
.smallBox .navBoxSmall{
  display: block;
}

.navBoxSmall{
  display: none;
}

.smallBox .navBoxBig{
  display:none;
}

/* Side Nav Display  */


.smallBox .rs-nav-item-content{
  font-size: 8px !important;
}

.smallBox .rs-dropdown-item-content{
  font-size: 8px !important;
}

.smallBox .rs-dropdown-toggle{
  font-size: 8px !important;
}

.smallBox .rs-icon{
  font-size: 8px !important;
}

.viewLine {
  text-decoration: none;
}
.viewLine:hover {
  text-decoration: none;
}

/* Carousel Slider */
.rs-carousel-toolbar{
  display: none !important;
}

.smallBox .carouseled{
  height: 30%;
}

/* Pagination */

.rs-pagination-btn-active a{
  color: red !important;
}

.pageRow{
  justify-content: center;
  text-align: center;
}

.bigPage{
  display: block;
}

.smallBox .bigPage{
  display: none;
}

.smallPage{
  display: none;
}

.smallBox .smallPage{
  margin-top: 10px;
  display: block;
}

/* Featured Section */

.featured{
  margin-top: 10px;
  display: flex; 
  flex-direction: row; 
}

.divideFeature{
  margin-top: 0;
}

.featured img
{
  width: 80%;
  display: inline-block;
	transition: 1s;
	cursor: pointer;
  margin-left: 20px;
  margin-right: 20px;
}

.featured img:hover
{
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

.smallBox .featured{
  flex-direction: column;
  margin-top: 0px;
}

.smallBox .featured img{
  display: list-item !important;
  width: 60%;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

/* Banner */

.bannerBox
{
  background-color:red;
	color: #fff;
	width: 95%;
  justify-content: left !important;
  text-align: left !important;
  margin-top: 30px;
  margin-left: 30px;
  padding-left: 10px;
	height: 40px;
	display: flex;
  border-radius: 10px 20px;
  text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
             0px 8px 13px rgba(0,0,0,0.1),
             0px 18px 23px rgba(0,0,0,0.1);
}

.smallBox .bannerBox{
  margin-top: 20px !important;
  height:  30px;
  width: 200px ;
}

.smallBox .bannerBox h2{
  font-size: 20px !important;
}

/*------website features------*/
.website-features
{
	margin: 60px 0;
}

.website-features img
{
    width:  65px;
    height: 60px;
}

.feature-text
{
	margin-top: 10px;
	float: right;
	width: 80%;
	padding-left: 20px;
	display: flex;

}

.feature-box
{
	padding-top: 20px;
	display: flex;
    flex-direction: row;
}

@media (max-width: 992px)
{
	.website-features img
	{
		width:  35px;
		height: 30px;
	}
}

/*------divider------*/
.smallBox .dividerBar{
  margin-bottom: 0px;
  margin-bottom: 0px;
}

/* General Active or Not*/
.topNav{
    background-color: red;  
    display: inline-block;
    padding-bottom: 5px;
    height: 60px;
    transition: height .5s;
    text-decoration: none;
    margin-bottom: 30px;
}

.smallBox .topNav{
    transition: height .5s;
    color: black;
}

.active .menuButton{
    font-size: 16px;
    color: black !important;
    transition: font-size .5s;
}

.topNav .menuButton{
    transition: font-size .5s;
}

.show .active {
    background: red !important;
}

.topNav.active{
    height: 50px;
    background: white;
    border-bottom: 2px;
    border-color: black;
    border-bottom-style: solid;
    transition: height .5s;
}

/* Link Item Style*/
.topNav .nav .nav-link {
    color: white;
    transition: color .5s;
}

.topNav .nav .nav-link:hover {
    text-decoration: none;
}

.topNav.active .nav .nav-link {
    color: black;
    transition: color .5s;
}

.topNav .nav .nav-link.active{
    color: black;
    text-decoration: none;
}

.topNav.active .nav .nav-link.active{
    color: red;
    text-decoration: none;
}

.smallBox .nav{
    float: right;
    padding-right: -10px;
}


/* Image */

.topNav img{
    margin-left: 20px;
    margin-right: 20px;
  }

  .smallBox .topNav img{
      width: 50px;
      height: 30px !important;
  }

  .dissapear{
      display: none;
  }


.catMain{
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: 5px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    box-sizing: border-box;
    justify-content: space-between;
    padding-left: 10px;
}

.smallBox .catMain{
    margin-left: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
}

.catCard{
    width: 200px;
    margin-bottom: 0;
    border-style: ridge;
    border-radius: 15px;
    border-color: #ffe6e6 !important;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
    transition: all .3s ease-in-out;
    justify-content: center;
    text-align: center;
}

.smallBox .catCard{
    margin-bottom: 0px;
    -webkit-transform: scale(.9);
            transform: scale(.9);
    padding-bottom: 0 !important;
}

.cardTitle{
    font-size: 20px;
    margin-bottom: 30px;
}

.smallBox .cardTitle{
    font-size: 15px;
    margin-bottom: 10px;
}

.cardStock{
    display: none;
}

.smallBox .cardStock{
  
    font-size: 10px;
}


.catCard:hover{
    border-style: none;
    box-shadow: none;
    background: #ffe6e6;
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    transition: all .3s ease-in-out;
}

.smallBox .catCard:hover{
    -webkit-transform: scale(1);
            transform: scale(1);
}

.catCard:hover .cardTitle{
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 15px;
}

.catCard:hover .cardStock{
    display: block;

}

.catCard img{
    margin-top: -6vw;
    width: 100%;
}

.smallBox .catCard img{
    margin-top: -7vw;
    width: 50%;
}

/* Button */
.button-71 {
    background-color: red !important;
    border: 0;
    width: 100%;
    border-radius: 56px;
    color: #fff !important;
    cursor: pointer;
    display: inline-block; 
    font-family: system-ui,-apple-system,system-ui,"Segoe UI",Roboto,Ubuntu,"Helvetica Neue",sans-serif;
    font-size: 18px;
    font-weight: 600;
    outline: 0;
    padding: 16px 21px;
    margin-bottom: 10px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: all .3s;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }

.smallBox .button-71{
    font-size: 12px;
}
  
  .button-71:before {
    background-color: transparent;
    background-color: initial;
    background-image: linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%);
    border-radius: 125px;
    content: "";
    height: 50%;
    left: 4%;
    opacity: .5;
    position: absolute;
    top: 0;
    transition: all .3s;
    width: 92%;
  }
  
  .button-71:hover {
    box-shadow: rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .1) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px;
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  
  @media (min-width: 768px) {
    .button-71 {
      padding: 16px 48px;
    }
  }
.page-header {
    margin-top: 50px;
    text-align: center;
    text-shadow: 2px 8px 6px rgba(0,0,0,0.2),
    0px -5px 35px rgba(255,255,255,0.3);
}

.page-header small {
    display: block;
}

.cart-row {
    margin: 10px ;
    padding: 10px 0;
    border-bottom: 1px solid #e1e1e1;
}

.cart-row div {
    text-align: center; 
}

.cart-row img.img-fluid { 
    margin: 0 auto; 
}

.cart-qty-input {
    font-size: 1em;
    margin-top: 10px;
    padding: 10px;
    width: 95%;
    border: 1px solid black;
  }

.product-remove{
	bottom: 20px;

    width: 50%;
	padding: 10px 10px;
	background-color: red;
	color: white;
	cursor: pointer;
	border-radius: 5px;
    text-align: center;
    justify-content: center;
}

.product-remove:hover{
	background-color: white;
	color: red;
	font-weight: 600;
	border: 1px solid red;
}

.tableHead{
    margin-top: 25px !important;
    padding-top: 12px;
    border-radius: 5px;
    background-color: black;
    color: white;
    text-align: center;
}

.mainCart{
    margin-top: 50px !important;
}

.cartLabel{
    font-weight: bold;
    display: none;
}

.cartBox{
    margin-top: 25px;
    padding-top: 50px;
    padding-bottom: 150px;
    background: #ffe6e6;
    padding-left: 15px;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}

.cartBox hr{
    border-top: 5px solid black;
    background-color: black;
    border-radius: 5px;
    margin-right: 100px !important;
}

.cartBox button{
    color: white;
    background-color: black;
    width: 90%;
    margin-top: 10px
}

.cartBox button:hover{
    background: red;
}


.smallBox .tableHead{
    display: none !important;
}

.smallBox .product-remove{
    margin-top: 10px;
    margin-bottom: 10px;
}

.smallBox .cartTitle{
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 0 !important;
    font-size: 12px;
}

.smallBox .labelsCart p{
    font-size: 12px !important;
    margin-bottom: 10px !important;
}

.smallBox .cartTitle h5{
    font-size: 15px;
}

.smallBox .cartTitle p{
    margin-bottom: 10px;
}

.smallBox .cartLabel{
    display: inline;
}

.smallBox .cart-row img.img-fluid{ 
    margin: 0 auto;
    width: 30%;
    height: 150px !important;
}

.smallBox .cartBox{
    justify-content: center !important;
    text-align: center;
}

.smallBox .cartBox hr{
    width: 96%;

}


/*  Main Page */
.accountBox{
    margin-top: 60px;
    width: 100%;
    min-height: 650px;
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0 0 2px rgba(15, 15, 15, 0.28);
    position: relative;
    overflow: hidden;

}

.topContainer{
    width: 100%;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 1.8em;
    padding-bottom: 5em;
}

.backDrop{
    width: 200%;
    height: 650px;
    position: absolute;
    display: flex;
    flex-direction: column;
    border-radius: 100%;
    background: red;
    top: -290px;
    left: -70px;
}

.headerContainer{
    width: 100%;
    display: flex;
    flex-direction: column;

}
.submitButton:disabled{
    display: none;
}


.headerContainer h2{
    font-size: 30px;
    font-weight: 600;
    line-height: 1.24;
    color: white;
    z-index: 10;
    margin: 0;
    margin-left: auto;
    margin-right: auto;
}

.headerContainer h5{
    color: white;
    font-weight: 500;
    font-size: 11px;
    margin-top: 6px;
    z-index: 10;
    margin-left: auto;
    margin-right: auto;
}

/*  Function Page */
.boxContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;


}

.formContainer{
    width: 75%;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 2.5px rgba(15, 15, 15, 0.19);
    max-width: 500px;
}

.muteLink{
    cursor: pointer;
    margin-top: 1em;
    font-size: 11px;
    color: rgba(200, 200, 200, 0.8);
    font-weight: 500;
    text-decoration: none;
}

.boldLink{
    font-size: 12px;
    color: red;
    font-weight: 500;
    text-decoration: none;
}

.formContainer input{
    width: 100%;
    
    height: 42px;
    outline: none;
    border: 1px solid rgba(200, 200, 200, 0.3);
    padding: 0px 10px;
    border-bottom: 1.4px solid transparent;
    transition: all 200ms ease-in-out;
    font-size: 12px;

}

.formContainer input:focus{
    outline: none;
    border: 2px solid red;
    border-bottom: none;
}

.formContainer input::-webkit-input-placeholder{
    color: rgba(200, 200, 200, 1);
}

.formContainer input:-ms-input-placeholder{
    color: rgba(200, 200, 200, 1);
}

.formContainer input::placeholder{
    color: rgba(200, 200, 200, 1);
}

.formContainer input:not(:last-of-type){
    border-bottom: 1.5px solid rgba(247, 100, 100, 0.4) ;
}

.submitButton {
    margin-top: 15px;
    background-color: red !important;
    outline:none !important; 
    border-style: none !important;
    cursor: pointer;
    transition: all, 240ms ease-in-out;
}



.submitButton:hover{
    -webkit-filter: brightness(1.9);
            filter: brightness(1.9);
}

.innerContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 1.8em;
}

.alert{
    line-height: 1px;
    font-size: 10px;
    margin: 0 !important;
}

/*  Forgot Password */
.emailModal{
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}


/* CSS */
.button-71 {
  background-color: #0078d0;
  border: 0;
  width: 100%;
  border-radius: 56px;
  color: #fff !important;
  cursor: pointer;
  display: inline-block; 
  font-family: system-ui,-apple-system,system-ui,"Segoe UI",Roboto,Ubuntu,"Helvetica Neue",sans-serif;
  font-size: 18px;
  font-weight: 600;
  outline: 0;
  padding: 16px 21px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all .3s;
  -ms-user-select: none;
      user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-71:before {
  background-color: transparent;
  background-color: initial;
  background-image: linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%);
  border-radius: 125px;
  content: "";
  height: 50%;
  left: 4%;
  opacity: .5;
  position: absolute;
  top: 0;
  transition: all .3s;
  width: 92%;
}

.button-71:hover {
  box-shadow: rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .1) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

@media (min-width: 768px) {
  .button-71 {
    padding: 16px 48px;
  }
}

.addForm p{
    margin-top: 0px;
    margin-bottom: 5px;
}

.addForm textarea{
    margin-top: 0px;
    margin-bottom: 5px;
    width: 100%;
    border-color: black;
}

.addForm textarea:focus{
    outline: none !important;
}

.addForm input{
    margin-top: 0px;
    margin-bottom: 5px;
    width: 100%;
}

.addForm input:focus{
    outline: none !important;
}

.contactContain{
    margin-top: 60px;  
    margin-bottom: 100px;
}



.contactLeft{
    background: red;
    padding-top: 70px;
    justify-content: center;
    text-align: center;
    border-radius: 20px;
}

.smallBox .contactLeft{
    border-top-right-radius: 0px;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
}

.contactRight{
    padding-top: 70px;
    justify-content: center;
    text-align: center;
}

.smallBox .contactRight{
    padding-bottom: 20px;
}


/*   Left Side Style   */
.contactTitle{
    margin-top: 100px;
    color: white;
    text-shadow: 2px 8px 6px rgba(0,0,0,0.2),
    0px -5px 35px rgba(255,255,255,0.3);
}

.smallBox .contactTitle{
    margin-top: 50px;
}

.formControl input{
    background: transparent !important;
}

.contactTitle h1{
    font-size: 32px;
    line-height: 10px;
}

.contactForm{
    padding: 0 30px;
}

.contactLeft form{
    margin-top: 50px;
    transition: all 4s ease-in-out;
}

.formControl{
    width: 100%;
    background: transparent;
    border: none;
    outline: none;
    border-bottom: 1px solid black;
    color: white;
    font-size: 18px;
    margin-bottom: 16px;
}

.contactLeft input{
    height: 45px;
}

.contactLeft input::-webkit-input-placeholder{
    color: white;
}

.contactLeft input:-ms-input-placeholder{
    color: white;
}

.contactLeft input::placeholder{
    color: white;
}

.contactLeft textarea::-webkit-input-placeholder{
    color: white;
}

.contactLeft textarea:-ms-input-placeholder{
    color: white;
}

.contactLeft textarea::placeholder{
    color: white;
}

.contactLeft form .submit{
    background: black;
    border-color: transparent;
    color: white;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 2px;
    height: 50px;
    margin-top: 20px;
    margin-bottom: 200px;
}

.smallBox .contactLeft form .submit{
    margin-bottom: 100px;
}

/*   Right Side Style   */
.contactTitleRight{
    margin-top: 100px;
    transition: all 4s ease-in-out;
    text-shadow: 2px 8px 6px rgba(0,0,0,0.2),
    0px -5px 35px rgba(255,255,255,0.3);
}

.smallBox .contactTitleRight{
    margin-top: 0;
}

.contactTitleRight h1{
    font-size: 32px;
    line-height: 10px;
}

.contactRightBox{
    margin-top: 50px;
}

.smallBox .contactRightBox{
    margin-top: 20px;
}

.contactRightBox i {
	background-color: #33383b;
	color: #ffffff;
	font-size: 25px;
	width: 38px;
	height: 38px;
	border-radius: 50%;
	text-align: center;
	line-height: 42px;
	margin: 10px 15px;
	vertical-align: middle;
  }

  .smallBox .contactRightBox i{
    margin: 0;
  }

  .contactRightBox .col-sm-4{
      text-align: end;
      margin-top: 10px;
  }

  .contactRightBox .col-sm-8{
      text-align: start;
      margin-top: 20px;
  }

  .contactRightBox p{
      margin: 0;
  }

  .boldPart{
      font-weight: bold;
      text-decoration: underline;
  }

  .smallBox .contactRightBox .col-sm-4{
      text-align: center !important;
      justify-content: center !important;
  }

  .smallBox .contactRightBox .col-sm-8{
    text-align: center !important;
    justify-content: center !important;
}

.smallBox .btmRow{
    margin-top: 20px;
}

.itemRight{
    width: 100%;
    display: flex;
}

.itemSlider .custom-slider  {
    margin-left: auto;
    margin-right: auto;
    width: 275px !important;
    background: white;
}

.itemRundown h2{ 
    margin-bottom: 15px;
    color: rgba(0,0,0,0.6);
    text-shadow: 2px 8px 6px rgba(0,0,0,0.2),
                    0px -5px 35px rgba(255,255,255,0.3);
}

.greyArea{
    background: #ffe6e6;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    padding-top: 20px;
    padding-bottom: 40px;
    padding-left: 10px;
}

.itemRundown button{
    background-color: red;
    border: none;

}

.itemRundown button:hover{
    background-color: black;
    border: none;
}

.itemDesc{
    margin-top: 20px;
    padding-left: 40px;
}

.itemDesc p{
    width: 80%;
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
}

.itemDesc li{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.descBanner{
    width: 80%;
    margin-left: 5%;
    margin-right: auto;
    width: 400px;
}

.smallBox .descBanner{
    margin-left: -10px;
    width: 100%;
}

/*  Small Box */
.smallBox .itemSlider .custom-slider  {
    width: 275px !important;
    background: white;
  }
  
  .smallBox .itemSlider img{
      width: 100% ;
      height: 100%;
  }
  
  .smallBox .itemRundown{
      font-size: 15px;
      justify-content: center;
      text-align: center;
      padding-left: 0;
  }
  
  .smallBox .itemRundown h2{
      font-size: 20px;
      margin-top: 30px;
  }

  .smallBox .itemRundown p{
      font-size: 13px;
  }
  
  .smallBox .itemDesc p {
      font-size: 12px;
      margin-top: 12px;
  }

  .smallBox .itemDesc li {
    font-size: 12px;
}

.addSuccess{
    margin-top: 15px !important;
    border-radius: 10px !important;
    text-align: center !important;
}
/* Footer */

.footer {
    background-color: #000;
    width: 100%;
    text-align: left;
    font-family: sans-serif;
    font-weight: bold;
    color: white;
    font-size: 16px;
    padding: 50px;
    margin-top: 50px;
    z-index: 10000;
  }

  .footer .footer-left,
  .footer .footer-center,
  .footer .footer-right {
	display: inline-block;
	vertical-align: top;
  z-index: 10000;
  }

  .footer-left {
	width: 33%;
	padding-right: 15px;
  z-index: 10000;
  }
  

  .footer .about {
	line-height: 20px;
	color: #ffffff;
	font-size: 13px;
	font-weight: normal;
	margin: 0;
  z-index: 10000;
  }

  .footer .about span {
	display: block;
	color: #ffffff;
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 20px;
  z-index: 10000;
  }

.icons {
  margin-top: 25px;
}

.footer .iconSpace
{
	padding-left: 10px;
	padding-right: 10px;
	height: 50px;
}

.footer .footer-center {
	width: 30%;
  }
  
  .footer .footer-center i {
	background-color: #33383b;
	color: #ffffff;
	font-size: 25px;
	width: 38px;
	height: 38px;
	border-radius: 50%;
	text-align: center;
	line-height: 42px;
	margin: 10px 15px;
	vertical-align: middle;
  }
  
  .footer .footer-center i.fa-envelope {
	font-size: 14px;
	line-height: 38px;
  }
  
  .footer .footer-center p {
	display: inline-block;
	color: #ffffff;
	vertical-align: middle;
	margin: 0;
  }
  
  .footer .footer-center p span {
	display: block;
	font-weight: normal;
	font-size: 14px;
	line-height: 2;
  }
  
  .footer .footer-center p a {
	color: red;
	text-decoration: none;

  }

  /* footer right*/

.footer .footer-right {
    width: 35%;
  }
  
  .footer h2 {
    color: #ffffff;
    font-size: 36px;
    font-weight: normal;
    margin: 0;
  }
  
  .footer h2 span {
    color: red;
  }
  
  .footer .menu {
    color: #ffffff;
    margin: 20px 0 12px;
    padding: 0;
  }
  
  .footer .menu a {
    display: inline-block;
    line-height: 1.8;
    text-decoration: none;
    color: inherit;
    padding-right: 10px;
  }
  
  .footer .menu a:hover {
    color: red;
  }
  
  .footer .name {
    color: red;
    font-size: 14px;
    font-weight: normal;
    margin: 0;
  }
  
  @media (max-width: 767px) {
    .footer {
      font-size: 14px;
    }
    .footer .footer-left,
    .footer .footer-center,
    .footer .footer-right {
      display: block;
      width: 100%;
      margin-bottom: 40px;
      text-align: center;
    }
    .footer .footer-center i {
      margin-left: 0;
    }
  }
