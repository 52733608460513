
.App {
  text-align: center;
  font-family: "Poppins";
}

/* Justify  */
.topContain{
  margin-top: 75px !important;
  display: flex; 
  flex-direction: row; 
  justify-content: space-between; 
}

.rightPanel{
  display: flex;
  flex-direction: column;
}

.widen{
  width: 100%;
}

/* Top Nav Display  */
.smallBox .navBoxSmall{
  display: block;
}

.navBoxSmall{
  display: none;
}

.smallBox .navBoxBig{
  display:none;
}

/* Side Nav Display  */


.smallBox .rs-nav-item-content{
  font-size: 8px !important;
}

.smallBox .rs-dropdown-item-content{
  font-size: 8px !important;
}

.smallBox .rs-dropdown-toggle{
  font-size: 8px !important;
}

.smallBox .rs-icon{
  font-size: 8px !important;
}

.viewLine {
  text-decoration: none;
}
.viewLine:hover {
  text-decoration: none;
}

/* Carousel Slider */
.rs-carousel-toolbar{
  display: none !important;
}

.smallBox .carouseled{
  height: 30%;
}

/* Pagination */

.rs-pagination-btn-active a{
  color: red !important;
}

.pageRow{
  justify-content: center;
  text-align: center;
}

.bigPage{
  display: block;
}

.smallBox .bigPage{
  display: none;
}

.smallPage{
  display: none;
}

.smallBox .smallPage{
  margin-top: 10px;
  display: block;
}

/* Featured Section */

.featured{
  margin-top: 10px;
  display: flex; 
  flex-direction: row; 
}

.divideFeature{
  margin-top: 0;
}

.featured img
{
  width: 80%;
  display: inline-block;
	transition: 1s;
	cursor: pointer;
  margin-left: 20px;
  margin-right: 20px;
}

.featured img:hover
{
    transform: scale(1.05);
}

.smallBox .featured{
  flex-direction: column;
  margin-top: 0px;
}

.smallBox .featured img{
  display: list-item !important;
  width: 60%;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

/* Banner */

.bannerBox
{
  background-color:red;
	color: #fff;
	width: 95%;
  justify-content: left !important;
  text-align: left !important;
  margin-top: 30px;
  margin-left: 30px;
  padding-left: 10px;
	height: 40px;
	display: flex;
  border-radius: 10px 20px;
  text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
             0px 8px 13px rgba(0,0,0,0.1),
             0px 18px 23px rgba(0,0,0,0.1);
}

.smallBox .bannerBox{
  margin-top: 20px !important;
  height:  30px;
  width: 200px ;
}

.smallBox .bannerBox h2{
  font-size: 20px !important;
}

/*------website features------*/
.website-features
{
	margin: 60px 0;
}

.website-features img
{
    width:  65px;
    height: 60px;
}

.feature-text
{
	margin-top: 10px;
	float: right;
	width: 80%;
	padding-left: 20px;
	display: flex;

}

.feature-box
{
	padding-top: 20px;
	display: flex;
    flex-direction: row;
}

@media (max-width: 992px)
{
	.website-features img
	{
		width:  35px;
		height: 30px;
	}
}

/*------divider------*/
.smallBox .dividerBar{
  margin-bottom: 0px;
  margin-bottom: 0px;
}
