.page-header {
    margin-top: 50px;
    text-align: center;
    text-shadow: 2px 8px 6px rgba(0,0,0,0.2),
    0px -5px 35px rgba(255,255,255,0.3);
}

.page-header small {
    display: block;
}

.cart-row {
    margin: 10px ;
    padding: 10px 0;
    border-bottom: 1px solid #e1e1e1;
}

.cart-row div {
    text-align: center; 
}

.cart-row img.img-fluid { 
    margin: 0 auto; 
}

.cart-qty-input {
    font-size: 1em;
    margin-top: 10px;
    padding: 10px;
    width: 95%;
    border: 1px solid black;
  }

.product-remove{
	bottom: 20px;

    width: 50%;
	padding: 10px 10px;
	background-color: red;
	color: white;
	cursor: pointer;
	border-radius: 5px;
    text-align: center;
    justify-content: center;
}

.product-remove:hover{
	background-color: white;
	color: red;
	font-weight: 600;
	border: 1px solid red;
}

.tableHead{
    margin-top: 25px !important;
    padding-top: 12px;
    border-radius: 5px;
    background-color: black;
    color: white;
    text-align: center;
}

.mainCart{
    margin-top: 50px !important;
}

.cartLabel{
    font-weight: bold;
    display: none;
}

.cartBox{
    margin-top: 25px;
    padding-top: 50px;
    padding-bottom: 150px;
    background: #ffe6e6;
    padding-left: 15px;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}

.cartBox hr{
    border-top: 5px solid black;
    background-color: black;
    border-radius: 5px;
    margin-right: 100px !important;
}

.cartBox button{
    color: white;
    background-color: black;
    width: 90%;
    margin-top: 10px
}

.cartBox button:hover{
    background: red;
}


.smallBox .tableHead{
    display: none !important;
}

.smallBox .product-remove{
    margin-top: 10px;
    margin-bottom: 10px;
}

.smallBox .cartTitle{
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 0 !important;
    font-size: 12px;
}

.smallBox .labelsCart p{
    font-size: 12px !important;
    margin-bottom: 10px !important;
}

.smallBox .cartTitle h5{
    font-size: 15px;
}

.smallBox .cartTitle p{
    margin-bottom: 10px;
}

.smallBox .cartLabel{
    display: inline;
}

.smallBox .cart-row img.img-fluid{ 
    margin: 0 auto;
    width: 30%;
    height: 150px !important;
}

.smallBox .cartBox{
    justify-content: center !important;
    text-align: center;
}

.smallBox .cartBox hr{
    width: 96%;

}

