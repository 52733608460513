/* Footer */

.footer {
    background-color: #000;
    width: 100%;
    text-align: left;
    font-family: sans-serif;
    font-weight: bold;
    color: white;
    font-size: 16px;
    padding: 50px;
    margin-top: 50px;
    z-index: 10000;
  }

  .footer .footer-left,
  .footer .footer-center,
  .footer .footer-right {
	display: inline-block;
	vertical-align: top;
  z-index: 10000;
  }

  .footer-left {
	width: 33%;
	padding-right: 15px;
  z-index: 10000;
  }
  

  .footer .about {
	line-height: 20px;
	color: #ffffff;
	font-size: 13px;
	font-weight: normal;
	margin: 0;
  z-index: 10000;
  }

  .footer .about span {
	display: block;
	color: #ffffff;
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 20px;
  z-index: 10000;
  }

.icons {
  margin-top: 25px;
}

.footer .iconSpace
{
	padding-left: 10px;
	padding-right: 10px;
	height: 50px;
}

.footer .footer-center {
	width: 30%;
  }
  
  .footer .footer-center i {
	background-color: #33383b;
	color: #ffffff;
	font-size: 25px;
	width: 38px;
	height: 38px;
	border-radius: 50%;
	text-align: center;
	line-height: 42px;
	margin: 10px 15px;
	vertical-align: middle;
  }
  
  .footer .footer-center i.fa-envelope {
	font-size: 14px;
	line-height: 38px;
  }
  
  .footer .footer-center p {
	display: inline-block;
	color: #ffffff;
	vertical-align: middle;
	margin: 0;
  }
  
  .footer .footer-center p span {
	display: block;
	font-weight: normal;
	font-size: 14px;
	line-height: 2;
  }
  
  .footer .footer-center p a {
	color: red;
	text-decoration: none;

  }

  /* footer right*/

.footer .footer-right {
    width: 35%;
  }
  
  .footer h2 {
    color: #ffffff;
    font-size: 36px;
    font-weight: normal;
    margin: 0;
  }
  
  .footer h2 span {
    color: red;
  }
  
  .footer .menu {
    color: #ffffff;
    margin: 20px 0 12px;
    padding: 0;
  }
  
  .footer .menu a {
    display: inline-block;
    line-height: 1.8;
    text-decoration: none;
    color: inherit;
    padding-right: 10px;
  }
  
  .footer .menu a:hover {
    color: red;
  }
  
  .footer .name {
    color: red;
    font-size: 14px;
    font-weight: normal;
    margin: 0;
  }
  
  @media (max-width: 767px) {
    .footer {
      font-size: 14px;
    }
    .footer .footer-left,
    .footer .footer-center,
    .footer .footer-right {
      display: block;
      width: 100%;
      margin-bottom: 40px;
      text-align: center;
    }
    .footer .footer-center i {
      margin-left: 0;
    }
  }