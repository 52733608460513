.itemRight{
    width: 100%;
    display: flex;
}

.itemSlider .custom-slider  {
    margin-left: auto;
    margin-right: auto;
    width: 275px !important;
    background: white;
}

.itemRundown h2{ 
    margin-bottom: 15px;
    color: rgba(0,0,0,0.6);
    text-shadow: 2px 8px 6px rgba(0,0,0,0.2),
                    0px -5px 35px rgba(255,255,255,0.3);
}

.greyArea{
    background: #ffe6e6;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    padding-top: 20px;
    padding-bottom: 40px;
    padding-left: 10px;
}

.itemRundown button{
    background-color: red;
    border: none;

}

.itemRundown button:hover{
    background-color: black;
    border: none;
}

.itemDesc{
    margin-top: 20px;
    padding-left: 40px;
}

.itemDesc p{
    width: 80%;
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
}

.itemDesc li{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.descBanner{
    width: 80%;
    margin-left: 5%;
    margin-right: auto;
    width: 400px;
}

.smallBox .descBanner{
    margin-left: -10px;
    width: 100%;
}

/*  Small Box */
.smallBox .itemSlider .custom-slider  {
    width: 275px !important;
    background: white;
  }
  
  .smallBox .itemSlider img{
      width: 100% ;
      height: 100%;
  }
  
  .smallBox .itemRundown{
      font-size: 15px;
      justify-content: center;
      text-align: center;
      padding-left: 0;
  }
  
  .smallBox .itemRundown h2{
      font-size: 20px;
      margin-top: 30px;
  }

  .smallBox .itemRundown p{
      font-size: 13px;
  }
  
  .smallBox .itemDesc p {
      font-size: 12px;
      margin-top: 12px;
  }

  .smallBox .itemDesc li {
    font-size: 12px;
}

.addSuccess{
    margin-top: 15px !important;
    border-radius: 10px !important;
    text-align: center !important;
}